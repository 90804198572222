import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import CognizantImage from '../images/solutions/cognizant.jpg'
import EcommerceImage from '../images/solutions/ecommerce.jpg'
import PayImage from '../images/solutions/pay.jpg'
import PulseImage from '../images/solutions/pulse.jpg'
import networking from '../images/services/networking.jpg'
import performance from '../images/services/performance.jpg'
import rationalizaion from '../images/services/rationalizaion.jpg'
import security from '../images/services/security.jpg'

import SmileyImage from '../images/solutions/smiley.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

const faq = () => {
    return (
        <Layout pageTitle="PragICTS | Pay | Faq">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                            <h2>COMMON <span> QUESTIONS</span></h2>
                   
                                <div className="horizonral-subtitle"><span>FAQ</span></div>
                            </div>
            
                        </div>
                    </section>
                    <section data-scrollax-parent="true" className='dark-bg sinsec-dec sinsec-dec2 parallax-section' id="sec2">
                        <div className="container">
                        <div className="section-title">
                       
                   
                                <div className="horizonral-subtitle"><span>FAQ</span></div>
                            </div>
                            <div class="fl-wrap">
      <div class="accordion">
        <a class="toggle" href="#">
          What do you mean by 100% customizable ?<span></span>
        </a>
        <div class="accordion-inner">
          <ul>
            <li>
              {" "}
              The Pay platform is customized to fit your exact company and
              business vertical needs. PragICTS will make the required
              customization prior to deployment.
            </li>
          </ul>
        </div>
        <a class="toggle" href="#">
          What is the deployment time frame of Pay?<span></span>
        </a>
        <div class="accordion-inner">
          <ul>
            <li>
              {" "}
              It all depends on your customization needs. In most instances, we
              can deploy within 2 to 4 weeks.
            </li>
          </ul>
        </div>
        <a class="toggle" href="#">
          {" "}
          What kind of support do I get after deployment ?<span></span>
        </a>
        <div class="accordion-inner">
          <ul>
            <li>You get full support from PragICTS right throughout.</li>
          </ul>
        </div>
        <a class="toggle" href="#">
          {" "}
          How about User Training ?<span></span>
        </a>
        <div class="accordion-inner">
          <ul>
            <li>
              You are provided with full onsite user training with live mock
              runs from PragICTS.{" "}
            </li>
          </ul>
        </div>
        <a class="toggle" href="#">
          {" "}
          Do I require any additional hardware and software requirements and
          costs ?<span></span>
        </a>
        <div class="accordion-inner">
          <ul>
            <li>
              A Depends. In most instances, there is zero need for any hardware
              or software as the complete solution engineered on 100% open
              source technology stack. Therefore the Pay platform can be
              deployed on most configurations.
            </li>
          </ul>
        </div>
        <a class="toggle" href="#">
          {" "}
          Are there any hidden or indirect costs in addition to the monthly
          utilization fee ?<span></span>
        </a>
        <div class="accordion-inner">
          <ul>
            <li>No none at all. All costs are tabled upfront.</li>
          </ul>
        </div>
        <a class="toggle" href="#">
          How is my data backed-up ? <span></span>
        </a>
        <div class="accordion-inner">
          <ul>
            <li>It is backed-up as per your needs by PragICTS.</li>
          </ul>
        </div>
      </div>
    </div>
                        </div>
                        <div className="bg-parallax-module" data-position-top="70"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                 

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default faq
